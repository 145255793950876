import {ValidatorService} from "../../index";
import {validatorTypes} from "../../di/types";

export function useFormValidator() {
  const {$container} = useNuxtApp();
  const validatorService = $container.get<ValidatorService>(validatorTypes.ValidatorService);

  async function validate(schema: any, data: any) {
    return await validatorService.validate(schema, data);
  }

  return {
    validate,
  }
}
